<template>
  <div class="content">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-primary">
          <div class="card-icon">
            <md-icon>person</md-icon>
          </div>
          <h4 class="title">Fiadores(P.Moral)</h4>
        </md-card-header>
        <md-card-content>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por página</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
              <md-button class="md-primary" @click="ModalPersonalizado(1)" 
                >Nuevo</md-button
              >
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }" >
              <md-table-cell md-label="Id">{{ item.fiadorMid }}</md-table-cell>
              <md-table-cell md-label="Nombre">{{
                item.fiadorMnombresRlegal
              }}</md-table-cell>
              <md-table-cell md-label="Apellido Paterno">{{
                item.fiadorMapePaternoRlegal
              }}</md-table-cell>
              <md-table-cell md-label="Apellido Materno">{{
                item.fiadorMapeMaternoRlegal
              }}</md-table-cell>
              <md-table-cell md-label="Acciones">
                <md-button 
                  class="md-just-icon md-warning md-simple"
                  @click.native="ModalPersonalizado(2, item)"
                  
                >
                  <md-icon >edit</md-icon>
                </md-button>
                <md-button
                  class="md-just-icon md-danger md-simple"
                  @click.native="ModalPersonalizado(3, item)"
                >
                  <md-icon>close</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
               <caption>Descripción de los datos en el pie de la tabla</caption>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
          
        </md-card-actions>
      </md-card>
      <modal v-if="classicModal" @close="classicModalHide">
        <template slot="header">
          <md-field>
            <div class="md-layout">
              <div class="md-layout-item md-large-size-100">
                <tabs
                  :tab-name="[
                    'General',
                    'Domicilio Garantia',
                    'Domicilio Empresa',
                    'Acta Constitutiva',
                    'Poder Rep. Legal',
                  ]"
                  color-button="primary"
                  flex-row
                >
                  <h4 class="title" slot="header-title">Crear Fiador:</h4>

                  <template slot="tab-pane-1">                   
                    <div class="md-layout-item md-size-100 md-medium-size-100">
                      <div class="md-layout">
                        <div
                          class="md-layout-item md-size-30 md-medium-size-100"
                          style="posi"
                        >
                          <md-field>
                            <label v-if="!isBandera">Razon Social</label>
                            <label class="error" v-if="isBandera">Razon Social</label>
                            <md-input v-model="FiaddorMrazonSocial" required></md-input>
                          </md-field>
                        </div>
                        <div
                          class="md-layout-item md-size-33 md-medium-size-100"
                        >
                          <md-field>
                            <label v-if="!isBandera">RFC</label>
                            <label  class="error" v-if="isBandera">RFC</label>
                            <md-input v-model="FiadorMrfc" required></md-input>
                          </md-field>
                        </div>
                        <div
                          class="md-layout-item md-size-33 md-medium-size-100"
                        >
                          <md-field>
                            <label>Puesto</label>
                            <md-input v-model="FiadorMpuesto" ></md-input>
                          </md-field>
                        </div>
                        <div
                          class="md-layout-item md-size-33 md-medium-size-100"
                        >
                          <md-field>
                            <label v-if="!isBandera">Nombre(s) representante legal</label>
                            <label class="error" v-if="isBandera">Nombre(s) representante legal</label>
                            <md-input v-model="FiadorMnombresRlegal" required ></md-input>
                          </md-field>
                        </div>
                        <div
                          class="md-layout-item md-size-33 md-medium-size-100"
                        >
                          <md-field>
                            <label v-if="!isBandera">Apellido paterno representante legal</label>
                            <label class="error" v-if="isBandera">Apellido paterno representante legal</label>
                            <md-input
                              v-model="FiadorMapePaternoRlegal" required
                            ></md-input>
                            
                          </md-field>
                        </div>
                        <div
                          class="md-layout-item md-size-33 md-medium-size-100"
                        >
                          <md-field>
                            <label v-if="!isBandera">Apellido materno representante legal</label>
                            <label class="error" v-if="isBandera">Apellido materno representante legal</label>
                            <md-input
                              v-model="FiadorMapeMaternoRlegal" required 
                            ></md-input>
                          </md-field>
                        </div>
                        <div
                          class="md-layout-item md-size-33 md-medium-size-100"
                        >
                          <md-field>
                            <label>Telefono celular</label>
                            <md-input v-model="FiadorMcel"></md-input>
                          </md-field>
                        </div>
                        <div
                          class="md-layout-item md-size-33 md-medium-size-100"
                        >
                          <md-field>
                            <label>Telefono empresa</label>
                            <md-input v-model="FiadorMtel"></md-input>
                          </md-field>
                        </div>
                        <div
                          class="md-layout-item md-size-33 md-medium-size-100"
                        >
                          <md-field>
                            <label>E-Mail</label>
                            <md-input
                              v-model="correoElectFiadorMemailRlegalronico"
                            ></md-input>
                          </md-field>
                        </div>
                        <div
                          class="md-layout-item md-size-33 md-medium-size-100"
                        >
                          <md-field>
                            <label>Acta constitutiva No.</label>
                            <md-input v-model="FiadorMmactaNo"></md-input>
                          </md-field>
                        </div>
                        <div
                          class="md-layout-item md-size-33 md-medium-size-100"
                        >
                          <md-field>
                            <label>Giro</label>
                            <md-input v-model="FiadorMgiro"></md-input>
                          </md-field>
                        </div>
                        <div
                          class="md-layout-item md-size-33 md-medium-size-100"
                        >
                          <md-field>
                            <label>Pagina Web</label>
                            <md-input v-model="FiadorMweb"></md-input>
                          </md-field>
                        </div>
                        <div
                          class="md-layout-item md-size-33 md-medium-size-100"
                        >
                          <md-field>
                            <label>Tipo de identificacion</label>
                            <md-input v-model="TipoIdentificacion"></md-input>
                          </md-field>
                        </div>
                        <div
                          class="md-layout-item md-size-33 md-medium-size-100"
                        >
                          <md-field>
                            <label>No. de identificacion</label>
                            <md-input v-model="NumeroIdentificacion"></md-input>
                          </md-field>
                        </div>
                      </div>
                    </div>
                  </template>

                  <template slot="tab-pane-2">
                    <div class="md-layout-item md-size-100 md-medium-size-100">
        <div class="md-layout">
          <div class="md-layout-item md-size-33 md-medium-size-100" style="posi">
            <md-field>
                <label>Domicilio del inmueble en garantia</label>
                <md-input v-model="domicilioGarantia" ></md-input>
              </md-field>
          </div>
          <div class="md-layout-item md-size-33 md-medium-size-100">
            <md-field>
                <label>Colonia</label>
                <md-input v-model="coliniaGarantia" ></md-input>
              </md-field>
          </div>
          <div class="md-layout-item md-size-33 md-medium-size-100">
            <md-field>
                <label>Municipio o Alcaldia</label>
                <md-input v-model="delegacionGarantia" ></md-input>
              </md-field>
          </div>
          <div class="md-layout-item md-size-33 md-medium-size-100">
            <md-field>
                <label>Estado</label>
                <md-input v-model="estadoGarantia" ></md-input>
              </md-field>
          </div>
          <div class="md-layout-item md-size-33 md-medium-size-100">
            <md-field>
                <label>Codigo Postal</label>
                <md-input v-model="FiadorMCPGarantia" ></md-input>
              </md-field>
          </div>
          <div class="md-layout-item md-size-33 md-medium-size-100">
            <md-field>
               <label>Nombre de Escritura</label>
                <md-input v-model="nombreGrantia" ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-33 md-medium-size-100">
            <md-field>
               <label>Licenciado Notaria</label>
                <md-input v-model="licenciadoNotaria" ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-33 md-medium-size-100">
            <md-field>
               <label>Numero de Notaria</label>
                <md-input v-model="numnotariaGarantia" ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-33 md-medium-size-100">
            <md-field>
               <label>Distrito Judicial</label>
                <md-input v-model="distritoJudicial" ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-33 md-medium-size-100">
            <md-field>
               <label>Numero de Partida</label>
                <md-input v-model="numPartida" ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-33 md-medium-size-100">
            <md-field>
               <label>Volumen</label>
                <md-input v-model="volumen" ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-33 md-medium-size-100">
            <md-field>
               <label>Libro</label>
                <md-input v-model="libro" ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-33 md-medium-size-100">
            <md-field>
               <label>Seccion</label>
                <md-input v-model="seccion" ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-size-33 md-medium-size-100">
            <span>Fecha de Partida</span>
            <md-field>
                <md-input v-model="fechaPartida" type="date" ></md-input>                        
            </md-field>
          </div>
        </div>
      </div>
                  </template>

                  <template slot="tab-pane-3">
                    <div class="md-layout-item md-size-100 md-medium-size-100">
        <div class="md-layout">
          <div class="md-layout-item md-size-33 md-medium-size-100" style="posi">
            <md-field>
                <label>Domicilio en garantia</label>
                <md-input v-model="domicilioGempresa" ></md-input>
              </md-field>
          </div>
          <div class="md-layout-item md-size-33 md-medium-size-100">
            <md-field>
                <label>Colonia</label>
                <md-input v-model="coloniaEmpresa" ></md-input>
              </md-field>
          </div>
          <div class="md-layout-item md-size-33 md-medium-size-100">
            <md-field>
                <label>Municipio o Alcaldia</label>
                <md-input v-model="municipioEmpresa" ></md-input>
              </md-field>
          </div>
          <div class="md-layout-item md-size-33 md-medium-size-100">
            <md-field>
                <label>Estado</label>
                <md-input v-model="edoEmpresa" ></md-input>
              </md-field>
          </div>
          <div class="md-layout-item md-size-33 md-medium-size-100">
            <md-field>
                <label>Codigo Postal</label>
                <md-input v-model="cpEmpresa" ></md-input>
              </md-field>
          </div>
        </div>
      </div>
                  </template>

                  <template slot="tab-pane-4">
                    <div class="md-layout-item md-size-100 md-medium-size-100">
        <div class="md-layout">
          <div class="md-layout-item md-size-33 md-medium-size-100" style="posi">
            <md-field>
                <label>Numero de Acta Constitutiva</label>
                <md-input v-model="numActa" ></md-input>
              </md-field>
          </div>
          <div class="md-layout-item md-size-33 md-medium-size-100">
            <span>Fecha Acta Constitutiva</span>
            <md-field>
                <md-input v-model="fechaActa" type="date"></md-input>
              </md-field>
          </div>
          <div class="md-layout-item md-size-33 md-medium-size-100">
            <md-field>
                <label>Licenciado que emite</label>
                <md-input v-model="licemisorActa" ></md-input>
              </md-field>
          </div>
          <div class="md-layout-item md-size-33 md-medium-size-100">
            <md-field>
                <label>Numero de Notaria</label>
                <md-input v-model="numnotariaActa" ></md-input>
              </md-field>
          </div>
          <div class="md-layout-item md-size-33 md-medium-size-100">
            <md-field>
                <label>Numero RPP</label>
                <md-input v-model="numrppActa" ></md-input>
              </md-field>
          </div>
          <div class="md-layout-item md-size-33 md-medium-size-100">
            <span>Fecha RPP</span>
            <md-field>
                <md-input v-model="fecharppActa" type="date"></md-input>
              </md-field>
          </div>
        </div>
      </div>
                  </template>
                  <template slot="tab-pane-5">
                    <div class="md-layout-item md-size-100 md-medium-size-100">
        <div class="md-layout">
          <div class="md-layout-item md-size-33 md-medium-size-100" style="posi">
            <md-field>
                <label>Numero de Ecscritura</label>
                <md-input v-model="numescrituraPoder" ></md-input>
              </md-field>
          </div>
          <div class="md-layout-item md-size-33 md-medium-size-100">
            <span>Fecha Poder</span>
            <md-field>
                <md-input v-model="fechaPoder" type="date"></md-input>
              </md-field>
          </div>
          <div class="md-layout-item md-size-33 md-medium-size-100">
            <md-field>
                <label>Licenciado que emite el poder</label>
                <md-input v-model="licPoder" ></md-input>
              </md-field>
          </div>
          <div class="md-layout-item md-size-33 md-medium-size-100">
            <md-field>
                <label>Numero de Notaria</label>
                <md-input v-model="numnotariaPoder" ></md-input>
              </md-field>
          </div>   
        </div>
      </div>
                  </template>
                </tabs>
              </div>
            </div>
          </md-field>
          <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="classicModalHide"
          >
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="footer">
          <md-button class="md-danger md-simple" @click="classicModalHide"
            >Cerrar</md-button
          >
          <md-button
            v-if="this.visible == false"
            class="md-primary"
            @click.native="createOrUpdateOrDelete(datosModal.evento)"
            >{{ datosModal.boton }}</md-button
          >
          <md-button v-if="this.visible == true" class="md-primary" disabled>
                <div class="loader"></div>
          </md-button>
        </template>
      </modal>
    </div>
  </div>
</template>
<script>
import { Modal } from "@/components";
import { Tabs } from "@/components";
import store from "../../Store/index";
import Swal from "sweetalert2";
import Fuse from "fuse.js";
import servFiador from "@/Servicios/Operacion/fiadorM";
import mensaje from "../../Servicios/ManjeadorErrores/Mensajes";
export default {
  name: 'ErrorsMessages',
  computed: {
    usuario(){
       return store.state.usuario
    },
    modo(){
      return store.state.Modo;
    },
    general() {
      return store.state.General;
    },
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
  },
  data() {
    return {
      currentSort: "fiadorMid",
      currentSortOrder: "asc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      footerTable: ["Nombre", "Apellido Paterno", "Apellido Materno"],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      classicModal: false,
      datosModal: {
        titulo: "Crear Fiador",
        boton: "Confirmar",
        evento: 0,
      },
      FisicaMoralId: 0,
      fiadorMid: 0,
      //General
      FiaddorMrazonSocial: null,
      FiadorMrfc: null,
      FiadorMpuesto: null,
      FiadorMnombresRlegal: null,
      FiadorMapePaternoRlegal: null,
      FiadorMapeMaternoRlegal: null,
      FiadorMcel: null,
      FiadorMtel:null,
      correoElectFiadorMemailRlegalronico: null,
      FiadorMmactaNo: null,
      FiadorMgiro: null,
      FiadorMweb: null,
      TipoIdentificacion: null,
      NumeroIdentificacion: null,
      //Garantia:
      domicilioGarantia : null, coliniaGarantia: null,delegacionGarantia : null,estadoGarantia : null,FiadorMCPGarantia:null,nombreGrantia:null,licenciadoNotaria:null,
     numnotariaGarantia:null,distritoJudicial:null,numPartida:null,volumen:null,libro:null,seccion: null,fechaPartida : null,
     //Domicilio Empresa
     domicilioGempresa:null,coloniaEmpresa:null,municipioEmpresa:null,edoEmpresa:null,cpEmpresa:null,
     //Acta Constitutiva
     numActa:null,fechaActa:null,licemisorActa:null,numnotariaActa:null,numrppActa:null,fecharppActa:null,
     //Poder Representante Legal
     numescrituraPoder:null,licPoder:null,fechaPoder:null,numnotariaPoder:null,
     isBandera : false,
     visible : false,
    };
  },
  components: {
    Tabs,
    Modal,
  },
  methods: {
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    createOrUpdateOrDelete(exp) {
      this.visible = true;
      var data = {
        FisicaMoralId: this.general.FisicaMoralId,
        FiadorMId: this.fiadorMid,
        FiaddorMRazonSocial: this.FiaddorMrazonSocial,
        FiadorMRFC: this.FiadorMrfc,
        FiadorMPuestoRLegal: this.FiadorMpuesto,
        FiadorMNombresRLegal: this.FiadorMnombresRlegal,
        FiadorMApePaternoRLegal: this.FiadorMapePaternoRlegal,
        FiadorMApeMaternoRLegal: this.FiadorMapeMaternoRlegal,
        FiadorMTelefono: this.FiadorMtel,
        FiadorMCelularRLegal:this.FiadorMcel,
        FiadorMEmailRLegal:
        this.correoElectFiadorMemailRlegalronico,
        FiadorMmactaNo: this.FiadorMmactaNo,
        FiadorMGiro: this.FiadorMgiro,
        FiadorMWeb: this.FiadorMweb,
        TipoIdentificacion: this.TipoIdentificacion,
        NumeroIdentificacion: this.NumeroIdentificacion,
        //Domicilio garantia
        FiadorMDomicilioGarantia : this.domicilioGarantia,
        FiadorMColoniaGarantia: this.coliniaGarantia,
        FiadorMDelegacionGarantia : this.delegacionGarantia,
        FiadorMEstadoGarantia : this.estadoGarantia,
        FiadorMCPGarantia:this.FiadorMCPGarantia,
        FiadorMNombreEscrituraGarantia:this.nombreGrantia,
        FiadorMLicenciadoNotaria:this.licenciadoNotaria,
        FiadorMNumNotaria:this.numnotariaGarantia,
        FiadorMDistritoJudicial:this.distritoJudicial,
        FiadorMNumPartida: this.numPartida,
        FiadorMVolumen:this.volumen,
        FiadorMLibro:this.libro,
        FiadorMSeccion:this.seccion,
        FiadorMFechaPartida:this.fechaPartida,
        //Domicilip Empresa
        FiadorMDomicilioEmpresa:this.domicilioGempresa,FiadorMColoniaEmpresa:this.coloniaEmpresa,FiadorMDeleEmpresa:this.municipioEmpresa,FiadorMEstadoEmpresa:this.edoEmpresa,FiadorMCPEmpresa:this.cpEmpresa,
        //Acta Constitutiva
        FiadorMNumCons:this.numActa,
        FiadorMFechaCons:this.fechaActa,
        FiadorMLicenciadoCons:this.licemisorActa,
        FiadorMNumNotaCons:this.numnotariaActa,
        FiadorMNumRpp:this.numrppActa,
        FiadorMFechaRpp:this.fecharppActa,
        //Poder Representante Legal
        FiadorMNumEscPoder:this.numescrituraPoder,
        FiadorMLicenciadoPoder:this.licPoder,
        FiadorMFechaPoder:this.fechaPoder,
        FiadorMNumeroNotaPoder:this.numnotariaPoder
      };
      switch (exp) {
      case 1:
      servFiador
        .post(data)
        .then((response) => {
            // Si hay un campo 'mensajes' en la respuesta, asume que es un array de mensajes
            this.ProcesamosResponse(response.data);      
        })
        .catch((e) => {
          this.classicModalHide();
          mensaje.Error("FiadorPost-crud", e);
        });
      break;
        case 2:
          if(this.fiadorMid >0 ){
            servFiador
              .put(this.fiadorMid, data)
              .then((r) => {
                this.ProcesamosResponse(r.data);
              })
              .catch(function (e) {
                this.classicModalHide();
                mensaje.Error("menu-crud-put", e);
              });
          }           
          break;
      }
    },
    ProcesamosResponse(data) {
      data.forEach((e) => {
        let m = {
          mensaje: "",
          tipo: "",
        };
        switch (e.tipo) {
          case 2: //Atributos por completar
            // se agrega a la clase el error "md-valid" ó "md-error"
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            this.isBandera = true;
            this.visible = false;
            break;
          case 1: //Error
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            this.isBandera = true;
            this.visible = false;
            break;
          case 3: //success
            this.getDatos();
            this.classicModalHide();
            m.mensaje = e.mensaje;
            m.tipo = "success";
            this.notifyVue(m);
            this.isBandera = false;
            this.visible = false;
            break;
          case 4: //Information
            m.mensaje = e.mensaje;
            m.tipo = "info";
            this.notifyVue(m);
            break;
        }
      });
    },
    getDatos() {
      servFiador
        .getId(this.general.FisicaMoralId)
        .then((r) => {
          this.tableData = r.data;
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.tableData, {
            keys: [
              "fiadorMid",
              "FiadorMnombresRlegal",
              "FiadorMapePaternoRlegal",
              "FiadorMapeMaternoRlegal",
            ],
            threshold: 0.3,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    classicModalHide() {
      this.Clear();
      this.classicModal = false;
      this.isBandera = false;
    },
    ModalPersonalizado(exp, row) {
      switch (exp) {
        case 1: //Insertar
          this.fiadorMid = 0;
          this.classicModal = true;
          this.datosModal.titulo = "Crear Nuevo Fiador";
          this.datosModal.boton = "Confirmar";
          this.datosModal.evento = 1;
          break;
        case 2: //Actualizar
          this.RetriveOnlyData(row);
          this.classicModal = true;
          this.datosModal.titulo = "Editar Datos del Fiador";
          this.datosModal.boton = "Actualizar";
          this.datosModal.evento = 2;
          break;
        case 3: //Eliminar
          this.fiadorMid = row.fiadorMid;
          this.deleteRow(this.fiadorMid);
          break;
      }
    },
    deleteRow() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "md-button md-success",
          cancelButton: "md-button md-danger",
        },
        buttonsStyling: false,
      });
      if (this.fiadorMid > 0) {
        swalWithBootstrapButtons.fire({
          title: "!Eliminar!",
          text: `¿Deseas eliminar el siguiente registro: ${this.fiadorMid} ?`,
          type: "success",
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          confirmButtonText: "Si, eliminar!",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
        })
          .then((r) => {
            if (r.value) {
              servFiador
                .delete(this.fiadorMid)
                .then((response) => {
                  this.getDatos();
                  swalWithBootstrapButtons.fire({
                    title: "Eliminado!",
                    text: `Se ha eliminado el registro ${this.fiadorMid}`,
                    type: "success",
                    confirmButtonClass: "md-button md-success",
                    cancelButtonText: "Cancelar",
                    buttonsStyling: false,
                  });

                })
                .catch(function (e) {
                  mensaje.Error("usuario-crud-sercategorias-delete", e);
                });
            }
          })
          .catch(function (e) {
            mensaje.Error("usuario-crud-sercategorias-delete", e);
          });
      }
    },
    notifyVue(m) {
      this.$notify({
        message: m.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: m.tipo,
      });
    },
    Clear() {
      //Gneral
      this.fiadorMid = 0;
      this.FiadorMapePaternoRlegal = null;
      this.FiadorMapeMaternoRlegal = null;
      this.FiadorMnombresRlegal = null;
      this.FiaddorMrazonSocial = null;
      this.FiadorMrfc=null;
      this.FiadorMpuesto=null;
      this.FiadorMnombresRlegal=null;
      this.FiadorMapePaternoRlegal=null;
      this.FiadorMapeMaternoRlegal=null;
      this.FiadorMcel=null;
      this.FiadorMtel=null;
      this.correoElectFiadorMemailRlegalronico=null;
      this.FiadorMmactaNo= null;
      this.FiadorMgiro= null;
      this.FiadorMweb= null;
      this.TipoIdentificacion= null;
      this.NumeroIdentificacion= null;
      //Domicilio Garantia:
    this.domicilioGarantia= null; this.coliniaGarantia= null;this.delegacionGarantia= null;this.estadoGarantia= null;this.FiadorMCPGarantia=null;this.nombreGrantia=null;this.licenciadoNotaria=null;
    this.numnotariaGarantia=null;this.distritoJudicial=null;this.numPartida=null;this.volumen=null;this.libro=null;this.seccion= null;this.fechaPartida= null;
    //Domicilio Empresa
    this.domicilioGempresa=null;this.coloniaEmpresa=null;this.municipioEmpresa=null;this.edoEmpresa=null;this.cpEmpresa=null;
    //Acta Constitutiva
    this.numActa=null;this.fechaActa=null;this.licemisorActa=null;this.numnotariaActa=null;this.numrppActa=null;this.fecharppActa=null;
    //Poder Representante Legal
    this.numescrituraPoder=null;this.licPoder=null;this.fechaPoder=null;this.numnotariaPoder=null;
    },
    RetriveOnlyData(row) {
      //General
      this.fiadorMid = row.fiadorMid;
      this.FiadorMapePaternoRlegal = row.fiadorMapePaternoRlegal;
      this.FiadorMapeMaternoRlegal = row.fiadorMapeMaternoRlegal;
      this.FiadorMnombresRlegal = row.fiadorMnombresRlegal;
      this.FiadorMrfc = row.fiadorMrfc;
      this.FiadorMmactaNo = row.fiadorMmactaNo;
      this.FiadorMtel = row.fiadorMtelefono;
      this.FiadorMcel = row.fiadorMcelularRlegal;
      this.FiadorMweb = row.fiadorMweb;
      this.FiaddorMrazonSocial = row.fiaddorMrazonSocial;
      this.FisicaMoralId = row.FisicaMoralId;
      this.FiadorMpuesto = row.fiadorMpuestoRlegal;
      this.FiadorMgiro = row.fiadorMgiro;
      this.TipoIdentificacion = row.tipoIdentificacion;
      this.NumeroIdentificacion = row.numeroIdentificacion;
      this.correoElectFiadorMemailRlegalronico = row.fiadorMemailRlegal;
      //Domicilio Garantia
      this.domicilioGarantia= row.fiadorMdomicilioGarantia; this.coliniaGarantia= row.fiadorMcoloniaGarantia;this.delegacionGarantia= row.fiadorMdelegacionGarantia;this.estadoGarantia= row.fiadorMestadoGarantia;this.FiadorMCPGarantia = row.fiadorMcpgarantia;this.nombreGrantia=row.fiadorMnombreEscrituraGarantia;this.licenciadoNotaria=row.fiadorMlicenciadoNotaria;
      this.numnotariaGarantia=row.fiadorMnumNotaria;this.distritoJudicial=row.fiadorMdistritoJudicial;this.numPartida=row.fiadorMnumPartida;this.volumen=row.fiadorMvolumen;this.libro=row.fiadorMlibro;this.seccion= row.fiadorMseccion;this.fechaPartida= row.fiadorMfechaPartida;
    //Domicilio EMpresa
    this.domicilioGempresa=row.fiadorMdomicilioEmpresa;this.coloniaEmpresa=row.fiadorMcoloniaEmpresa;this.municipioEmpresa=row.fiadorMdeleEmpresa;this.edoEmpresa=row.fiadorMestadoEmpresa;this.cpEmpresa=row.fiadorMcpempresa;
     //Acta contituitva
     this.numActa=row.fiadorMnumCons;this.fechaActa=row.fiadorMfechaCons;this.licemisorActa=row.fiadorMlicenciadoCons;this.numnotariaActa=row.fiadorMnumNotaCons;this.numrppActa=row.fiadorMnumRpp;this.fecharppActa=row.fiadorMfechaRpp;
     //Poder Representante Legal
     this.numescrituraPoder=row.fiadorMnumEscPoder;this.licPoder=row.fiadorMlicenciadoPoder;this.fechaPoder=row.fiadorMfechaPoder;this.numnotariaPoder=row.fiadorMnumeroNotaPoder;
     this.visible = false;
    },
  },
  created() {
    this.FisicaMoralId = this.general.FisicaMoralId;
    this.getDatos();
  },
  mounted() { },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */

  },
};
</script>
<style lang="css" scoped>
.error{
  color: brown !important;
}
.loader {
  width: 90px;
  height: 20px;
  font-size: 12px;
  clip-path: inset(0 2ch 0 0);
  animation: l4 1s steps(4) infinite;
}
.loader:before {
  content:"Guardando....."
}
@keyframes l4 {to{clip-path: inset(0 -1ch 0 0)}}
</style>